import React, { useState } from 'react';
import { register } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/Auth.css';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [key, setKey] = useState('');
  const [country, setCountry] = useState('');
  const [countryOther, setCountryOther] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [specialityOther, setSpecialityOther] = useState('');
  const [trainingLevel, setTrainingLevel] = useState('');
  const [trainingLevelOther, setTrainingLevelOther] = useState('');
  const [institution, setInstitution] = useState('');
  const [institutionOther, setInstitutionOther] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await register(
        username,
        email,
        password,
        key,
        country,
        country === 'OTHER' ? countryOther : '',
        speciality,
        speciality === 'OTHER' ? specialityOther : '',
        trainingLevel,
        trainingLevel === 'OTHER' ? trainingLevelOther : '',
        institution,
        institution === 'OTHER' ? institutionOther : '',
        firstName,
        surname
      );
      navigate('/login');
    } catch (err) {
      console.error('Registration error:', err.response?.data);
      if (err.response?.data) {
        // Handle specific field errors
        const errorMessages = Object.entries(err.response.data)
          .map(([field, errors]) => `${field}: ${errors}`)
          .join('\n');
        setError(errorMessages);
      } else {
        setError('Registration failed. Please try again.');
      }
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2>Register</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Surname:</label>
            <input type="text" id="surname" value={surname} onChange={(e) => setSurname(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <select id="country" value={country} onChange={(e) => setCountry(e.target.value)} required>
              <option value="">Select a country</option>
              <option value="UK">United Kingdom</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="AU">Australia</option>
              <option value="NZ">New Zealand</option>
              <option value="IE">Ireland</option>
              <option value="OTHER">Other</option>
            </select>
            {country === 'OTHER' && (
              <input
                type="text"
                placeholder="Please specify your country"
                value={countryOther}
                onChange={(e) => setCountryOther(e.target.value)}
                required
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="speciality">Speciality:</label>
            <select id="speciality" value={speciality} onChange={(e) => setSpeciality(e.target.value)} required>
              <option value="">Select a speciality</option>
              <option value="GEN_RAD">General Radiology</option>
              <option value="NEURO">Neuroradiology</option>
              <option value="MSK">Musculoskeletal Radiology</option>
              <option value="CHEST">Chest/Cardiothoracic Radiology</option>
              <option value="ABD">Abdominal Radiology</option>
              <option value="INT_RAD">Interventional Radiology</option>
              <option value="NUC_MED">Nuclear Medicine</option>
              <option value="PED_RAD">Pediatric Radiology</option>
              <option value="BREAST">Breast Imaging</option>
              <option value="EMERG">Emergency Radiology</option>
              <option value="ONC_RAD">Oncologic Radiology</option>
              <option value="RAD_ONC">Radiation Oncology</option>
              <option value="HEAD_NECK">Head and Neck Radiology</option>
              <option value="VASC">Vascular Radiology</option>
              <option value="MOL_IMG">Molecular Imaging</option>
              <option value="MAMMO">Mammography</option>
              <option value="CV_RAD">Cardiovascular Radiology</option>
              <option value="GI_RAD">Gastrointestinal Radiology</option>
              <option value="GU_RAD">Genitourinary Radiology</option>
              <option value="AI_RAD">AI/Informatics in Radiology</option>
              <option value="OTHER">Other</option>
            </select>
            {speciality === 'OTHER' && (
              <input
                type="text"
                placeholder="Please specify your speciality"
                value={specialityOther}
                onChange={(e) => setSpecialityOther(e.target.value)}
                required
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="trainingLevel">Level of Training:</label>
            <select id="trainingLevel" value={trainingLevel} onChange={(e) => setTrainingLevel(e.target.value)} required>
              <option value="">Select training level</option>
              <option value="MED_STU">Medical Student</option>
              <option value="FOUND">Foundation Doctor</option>
              <option value="RES_Y1">Resident Year 1</option>
              <option value="RES_Y2">Resident Year 2</option>
              <option value="RES_Y3">Resident Year 3</option>
              <option value="RES_Y4">Resident Year 4</option>
              <option value="RES_Y5">Resident Year 5</option>
              <option value="FELLOW">Fellow</option>
              <option value="CONS">Consultant/Attending</option>
              <option value="OTHER">Other</option>
            </select>
            {trainingLevel === 'OTHER' && (
              <input
                type="text"
                placeholder="Please specify your training level"
                value={trainingLevelOther}
                onChange={(e) => setTrainingLevelOther(e.target.value)}
                required
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="institution">Institution:</label>
            <select id="institution" value={institution} onChange={(e) => setInstitution(e.target.value)} required>
              <option value="">Select an institution</option>
              <option value="NNUH">Norfolk and Norwich University Hospital</option>
              <option value="UNIV">University Hospital</option>
              <option value="PRIV">Private Practice</option>
              <option value="CLIN">Clinic</option>
              <option value="OTHER">Other</option>
            </select>
            {institution === 'OTHER' && (
              <input
                type="text"
                placeholder="Please specify your institution"
                value={institutionOther}
                onChange={(e) => setInstitutionOther(e.target.value)}
                required
              />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="key">Registration Key:</label>
            <input type="text" id="key" value={key} onChange={(e) => setKey(e.target.value)} required />
          </div>
          <button type="submit">Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
