import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_URL = isDevelopment 
  ? 'http://127.0.0.1:8000/auth/'
  : 'https://herokuradcopilot-6fbe85a5512a.herokuapp.com/auth/';

export const register = (
  username, 
  email, 
  password, 
  key, 
  country, 
  countryOther,
  speciality, 
  specialityOther,
  trainingLevel, 
  trainingLevelOther,
  institution, 
  institutionOther,
  firstName, 
  surname
) => {
  return axios.post(API_URL + 'register/', {
    username,
    email,
    password,
    key,
    first_name: firstName,
    surname,
    country,
    country_other: countryOther,
    speciality,
    speciality_other: specialityOther,
    training_level: trainingLevel,
    training_level_other: trainingLevelOther,
    institution,
    institution_other: institutionOther
  });
};

export const login = (username, password) => {
  return axios.post(API_URL + 'token/', {
    username,
    password
  });
};

export const refreshToken = (refresh) => {
  return axios.post(API_URL + 'token/refresh/', {
    refresh
  });
};

export const requestPasswordReset = (email) => {
  return axios.post(API_URL + 'password-reset/', {
    email: email
  });
};

export const resetPassword = (token, email, new_password) => {
  return axios.post(API_URL + 'password-reset/confirm/', {
    token,
    email,
    new_password
  });
};
