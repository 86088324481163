import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';
import '../styles/Sidebar.css';
import ReactMarkdown from 'react-markdown';

const Sidebar = ({ 
  isOpen, 
  toggleSidebar, 
  onLogout, 
  onSelectReport,
  text,
  setText,
  setSuggestion,
  setShowPopup,
  selectedText,
  autoCompleteEnabled,
  setAutoCompleteEnabled,
  ragEnabled,
  setRagEnabled,
  chatMessages,
  chatLoading,
  onChatStart,
  onChatMessage,
  onClearChat,
  onCopyToChat,
  chatInput,
  setChatInput
}) => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeView, setActiveView] = useState('main');
  const [newMessage, setNewMessage] = useState('');
  const [isButtonsCollapsed, setIsButtonsCollapsed] = useState(false);
  const [processingReportId, setProcessingReportId] = useState(null);
  const [showEmbeddingPopup, setShowEmbeddingPopup] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, reportId: null });
  const [profile, setProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [guidelines, setGuidelines] = useState([]);
  const [guidelinesLoading, setGuidelinesLoading] = useState(false);
  const [guidelinesSummary, setGuidelinesSummary] = useState('');
  
  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get('/api/report-history/');
        const reportData = Array.isArray(response.data) ? response.data : [];
        setReports(reportData);
        
        // Debug logging for reports
        console.group('Report History Debug');
        console.log('Total reports fetched:', reportData.length);
        console.log('Reports being used as context:', 
          reportData.filter(report => report.is_context)
            .map(report => ({
              id: report.id,
              preview: report.content.substring(0, 50) + '...',
              created_at: report.created_at,
              embedding_status: report.embedding_status
            }))
        );
        console.groupEnd();

        setError(null);
      } catch (error) {
        console.error('Error fetching report history:', error);
        setError('Failed to fetch reports. Please try again.');
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, []);

  const checkEmbeddingStatus = async (reportId) => {
    try {
      const response = await axiosInstance.get(`/api/report-history/${reportId}/`);
      const status = response.data.embedding_status;
      
      if (status === 'COMPLETED' || status === 'FAILED') {
        setShowEmbeddingPopup(false);
        setProcessingReportId(null);
        
        // Refresh reports list to show updated status
        const reportsResponse = await axiosInstance.get('/api/report-history/');
        setReports(Array.isArray(reportsResponse.data) ? reportsResponse.data : []);
      } else if (status === 'PENDING') {
        // Continue polling if still pending
        setTimeout(() => checkEmbeddingStatus(reportId), 2000); // Poll every 2 seconds
      }
    } catch (error) {
      console.error('Error checking embedding status:', error);
      setShowEmbeddingPopup(false);
      setProcessingReportId(null);
    }
  };

  const handleSaveAndCopy = async () => {
    if (!text.trim()) {
      return;
    }

    try {
      // First copy to clipboard
      await navigator.clipboard.writeText(text);

      // Show success popup
      setShowPopup(true);

      // Clear the text area
      setText('');
      setSuggestion('');
      
      // If RAG is enabled, show embedding popup
      if (ragEnabled) {
        setShowEmbeddingPopup(true);
      }

      // Save report with pending status
      const saveResponse = await axiosInstance.post('/api/report-history/', {
        content: text,
        create_embedding: ragEnabled
      });
      
      // Start polling for embedding status if RAG is enabled
      if (ragEnabled && saveResponse.data.id) {
        setProcessingReportId(saveResponse.data.id);
        checkEmbeddingStatus(saveResponse.data.id);
      }
      
      // Refresh the reports list to show the new pending report
      const response = await axiosInstance.get('/api/report-history/');
      setReports(Array.isArray(response.data) ? response.data : []);

      // Hide copy success popup after 2 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);

    } catch (error) {
      console.error('Error saving/copying report:', error);
      alert('Failed to save or copy the report. Please try again.');
      setShowEmbeddingPopup(false);
    }
  };

  const handleChatClick = () => {
    setActiveView(activeView === 'chat' ? 'main' : 'chat');
  };

  const handleSendMessage = async () => {
    if (chatInput.trim() === '') return;
    onChatMessage(chatInput);
    setChatInput('');
  };

  const handleDeleteReport = async (reportId, e) => {
    e.stopPropagation(); // Prevent report selection when clicking delete
    setDeleteConfirmation({ show: true, reportId });
  };

  const confirmDelete = async () => {
    try {
      await axiosInstance.delete(`/api/report-history/${deleteConfirmation.reportId}/`);
      // Update reports list after deletion
      const response = await axiosInstance.get('/api/report-history/');
      setReports(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error deleting report:', error);
      alert('Failed to delete report. Please try again.');
    }
    setDeleteConfirmation({ show: false, reportId: null });
  };

  const fetchProfile = async () => {
    try {
      setIsProfileLoading(true);
      const response = await axiosInstance.get('auth/user/profile/');
      // Get the first item since the API returns an array with one profile
      const profileData = response.data[0];
      console.log('Profile data:', response.data[0]);
      setProfile(profileData);
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setIsProfileLoading(false);
    }
  };

  const checkGuidelines = async () => {
    if (!text) return;
    
    setActiveView('guidelines');
    setGuidelinesLoading(true);
    try {
      const response = await axiosInstance.post('/api/radguide/match/', {
        text: text
      });
      setGuidelines(response.data.guidelines || []);
      setGuidelinesSummary(response.data.summary || '');
      setActiveView('guidelines');
    } catch (error) {
      console.error('Error checking guidelines:', error);
      setGuidelines([]);
      setGuidelinesSummary('');
    } finally {
      setGuidelinesLoading(false);
    }
  };

  const renderChatView = () => (
    <div className="sidebar-section">
      <div className="sidebar-section-content chat-view">
        <div className="chat-header">
          {selectedText && (
            <button 
              className="copy-to-chat-button"
              onClick={onCopyToChat}
            >
              Copy Selection to Chat
            </button>
          )}
          {chatMessages.length > 0 && (
            <button 
              className="chat-close-button" 
              onClick={() => {
                onClearChat();
                setNewMessage('');
              }}
            >
              Clear Chat
            </button>
          )}
        </div>
        <div className="chat-messages">
          {chatMessages.map((message, index) => (
            <div key={index} className={`chat-message ${message.role}`}>
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </div>
          ))}
          {chatLoading && <p>Loading...</p>}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            placeholder="Type your message..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );

  const renderSettings = () => {
    return (
      <div className="sidebar-section">
        <div className="sidebar-section-content">
          <div className="setting-item">
            <label className="switch-label">
              <span>Enable RadSuggestions</span>
              <div className="switch">
                <input
                  type="checkbox"
                  checked={autoCompleteEnabled}
                  onChange={(e) => setAutoCompleteEnabled(e.target.checked)}
                />
                <span className="slider"></span>
              </div>
            </label>
          </div>
          <div className="setting-item">
            <label className="switch-label">
              <span>Enable Custom Report Engine</span>
              <div className="switch">
                <input
                  type="checkbox"
                  checked={ragEnabled}
                  onChange={(e) => setRagEnabled(e.target.checked)}
                />
                <span className="slider"></span>
              </div>
            </label>
          </div>

          <div className="settings-submenu">
            <button 
              className="sidebar-button"
              onClick={() => setActiveView('help')}
            >
              Help
            </button>
            
            <button 
              className="sidebar-button"
              onClick={() => setActiveView('profile')}
            >
              Profile
            </button>
            
            <button 
              className="sidebar-button"
              onClick={onLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderReportHistory = () => (
    <div className="sidebar-section">
      <div className="sidebar-section-content">
        <div className="report-history-header">
          <h3>Report History</h3>
          <span className="report-count">
            Total Reports: {reports.length}
          </span>
        </div>
        {isLoading ? (
          <p>Loading reports...</p>
        ) : error ? (
          <p>{error}</p>
        ) : reports.length === 0 ? (
          <p>No reports found.</p>
        ) : (
          <ul className="report-list">
            {reports.map((report) => (
              <li 
                key={report.id} 
                className={`report-item ${report.is_context ? 'used-as-context' : ''} ${
                  report.id === processingReportId ? 'processing' : ''
                }`}
              >
                <div className="report-header">
                  <span className="report-date">
                    {report.created_at ? new Date(report.created_at).toLocaleString() : 'Date not available'}
                  </span>
                  <div className="report-actions">
                    {report.id === processingReportId ? (
                      <span className="embedding-status processing">
                        Processing...
                      </span>
                    ) : (
                      <>
                        {report.embedding_status === 'PENDING' && (
                          <span className="embedding-status pending">
                            Pending
                          </span>
                        )}
                        {report.embedding_status === 'COMPLETED' && (
                          <span className="embedding-status completed">
                            Ready
                          </span>
                        )}
                        {report.embedding_status === 'FAILED' && (
                          <span className="embedding-status failed">
                            Failed
                          </span>
                        )}
                        <button 
                          className="delete-button"
                          onClick={(e) => handleDeleteReport(report.id, e)}
                          title="Delete report"
                        >
                          ×
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="report-content" onClick={() => onSelectReport(report.content)}>
                  <p className="report-preview">
                    {report.content.substring(0, 50)}
                    {report.content.length > 50 ? '...' : ''}
                  </p>
                  {report.is_context && (
                    <span className="context-badge">Used as Context</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

  const renderProfile = () => (
    <div className="sidebar-section">
      <div className="sidebar-section-content">
        <h3>Profile Information</h3>

        {isProfileLoading ? (
          <div className="loading-spinner">Loading profile...</div>
        ) : profile ? (
          <div className="profile-info">
            <div className="info-group">
              <label>Name:</label>
              <span>{profile.first_name} {profile.surname}</span>
            </div>
            <div className="info-group">
              <label>Country:</label>
              <span>
                {profile.country === 'OTHER' 
                  ? profile.country_other 
                  : profile.country_display}
              </span>
            </div>
            <div className="info-group">
              <label>Speciality:</label>
              <span>
                {profile.speciality === 'OTHER' 
                  ? profile.speciality_other 
                  : profile.speciality_display}
              </span>
            </div>
            <div className="info-group">
              <label>Training Level:</label>
              <span>
                {profile.training_level === 'OTHER' 
                  ? profile.training_level_other 
                  : profile.training_level_display}
              </span>
            </div>
            <div className="info-group">
              <label>Institution:</label>
              <span>
                {profile.institution === 'OTHER' 
                  ? profile.institution_other 
                  : profile.institution_display}
              </span>
            </div>
          </div>
        ) : (
          <div>No profile data available</div>
        )}
      </div>
    </div>
  );

  const renderGuidelinesView = () => (
    <div className="sidebar-section">
      {guidelinesLoading ? (
        <div className="guidelines-loading">
          <div>
            <div className="guidelines-spinner" />
            <div className="guidelines-loading-text">
              Analyzing guidelines...
            </div>
          </div>
        </div>
      ) : (
        <div className="sidebar-section-content">
          <button 
            className="check-guidelines-btn" 
            onClick={checkGuidelines}
            disabled={!text || guidelinesLoading}
          >
            {guidelinesLoading ? 'Checking...' : 'Check Guidelines'}
          </button>

          {guidelinesSummary && (
            <div className="guidelines-summary">
              <h4>Summary</h4>
              <ReactMarkdown>{guidelinesSummary}</ReactMarkdown>
            </div>
          )}

          <div className="guidelines-list">
            {guidelines.map((guideline, index) => (
              <div key={index} className="guideline-item">
                <h4>{guideline.title}</h4>
                <div className="guideline-content">
                  <div className="most-relevant-section">
                    <h5>Most Relevant Section</h5>
                    <ReactMarkdown>{guideline.most_relevant_section}</ReactMarkdown>
                    <small>
                      Section Similarity: {(guideline.section_similarity * 100).toFixed(1)}%
                    </small>
                  </div>
                  <div className="full-guideline">
                    <h5>Full Guideline</h5>
                    <ReactMarkdown>{guideline.content}</ReactMarkdown>
                  </div>
                </div>
                <small>
                  Overall Similarity: {(guideline.similarity * 100).toFixed(1)}%
                </small>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const renderContent = () => {
    switch (activeView) {
      case 'help':
        return (
          <div className="sidebar-section">
            <div className="sidebar-section-content">
              <h3>Keyboard Shortcuts</h3>
              <ul>
                <li><strong>Ctrl + m:</strong> Start dictation</li>
                <li><strong>Shift + Tab:</strong> Insert first word of suggestion</li>
                <li><strong>Tab:</strong> Insert entire suggestion</li>
                <li><strong>Backspace:</strong> Clear suggestion</li>
              </ul>
            </div>
          </div>
        );

      case 'chat':
        return renderChatView();

      case 'history':
        return renderReportHistory();

      case 'settings':
        return renderSettings();

      case 'profile':
        return renderProfile();

      case 'guidelines':
        return renderGuidelinesView();

      default:
        return null;
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="sidebar-toggle" onClick={toggleSidebar} aria-label="Toggle Sidebar">
        <img src={process.env.NODE_ENV === 'production' ? '/static/white_logo_512.png' : 'white_logo_512.png'} alt="RadCoPilot Logo" />
      </button>
      
      <div className="sidebar-content">
        <div className="sidebar-title">
          <span className="rad">Rad</span><span className="copilot">CoPilot</span>
        </div>

        <div className="sidebar-buttons-container">
          <button 
            className="collapse-toggle"
            onClick={() => setIsButtonsCollapsed(!isButtonsCollapsed)}
          >
            {isButtonsCollapsed ? '▼' : '▲'}
          </button>
          <div className={`sidebar-buttons ${isButtonsCollapsed ? 'collapsed' : ''}`}>
            <button 
              className="sidebar-button"
              onClick={handleSaveAndCopy}
            >
              Save and Copy Report
            </button>
            
            <button 
              className={`sidebar-button ${activeView === 'history' ? 'active' : ''}`}
              onClick={() => setActiveView(activeView === 'history' ? 'main' : 'history')}
            >
              Report History
            </button>

            <button 
              className={`sidebar-button ${activeView === 'chat' ? 'active' : ''}`}
              onClick={handleChatClick}
            >
              RadChat
            </button>

            <button 
              className={`sidebar-button ${activeView === 'guidelines' ? 'active' : ''}`}
              onClick={checkGuidelines}
              disabled={guidelinesLoading}
            >
              {guidelinesLoading ? 'Checking Guidelines...' : 'Check Guidelines'}
            </button>

            <button 
              className={`sidebar-button ${activeView === 'settings' ? 'active' : ''}`}
              onClick={() => setActiveView(activeView === 'settings' ? 'main' : 'settings')}
            >
              Settings
            </button>
          </div>
        </div>

        {renderContent()}

        {showEmbeddingPopup && (
          <div className="embedding-popup">
            <div className="spinner"></div>
            <span>Creating embeddings for context search...</span>
          </div>
        )}

        {deleteConfirmation.show && (
          <div className="delete-confirmation-overlay">
            <div className="delete-confirmation-popup">
              <p>Are you sure you want to delete this report?</p>
              <div className="delete-confirmation-buttons">
                <button 
                  className="confirm-button"
                  onClick={confirmDelete}
                >
                  Delete
                </button>
                <button 
                  className="cancel-button"
                  onClick={() => setDeleteConfirmation({ show: false, reportId: null })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;