import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sidebar from './Sidebar';
import '../styles/RadCoPilot.css';
import { getAutocompletion, getChatResponse } from '../api/openai';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

const MicIcon = ({ isRecording }) => (
  <svg viewBox="0 0 24 24" fill={isRecording ? "#ff4444" : "#666"}>
    <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"/>
    <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>
  </svg>
);

const RadCoPilot = ({ onLogout }) => {
  const [text, setText] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const textareaRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const latestRequestIdRef = useRef(0);
  const [selectedText, setSelectedText] = useState('');
  const [autoCompleteEnabled, setAutoCompleteEnabled] = useState(true);
  const [ragEnabled, setRagEnabled] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [suggestionStatus, setSuggestionStatus] = useState('idle');
  const [isDictating, setIsDictating] = useState(false);
  const recognition = useRef(null);
  const [speechRecognitionSupported] = useState(!!SpeechRecognition);
  const [chatInput, setChatInput] = useState('');

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };
  
  const debouncedGetSuggestions = useCallback((newText) => {
    if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
    }

    latestRequestIdRef.current++;
    const currentRequestId = latestRequestIdRef.current;

    if (newText.trim() !== '') {
        setSuggestionStatus('loading');
        
        debounceTimeoutRef.current = setTimeout(async () => {
            try {
                const currentPosition = textareaRef.current.selectionStart;
                const beforeCursor = newText.slice(0, currentPosition);
                const afterCursor = newText.slice(currentPosition);
                const textWithCursor = `${beforeCursor}|${afterCursor}`;
                
                if (autoCompleteEnabled) {
                    const autocompletionResponse = await getAutocompletion(textWithCursor, ragEnabled);
                    
                    if (currentRequestId === latestRequestIdRef.current) {
                        if (autocompletionResponse) {
                            setSuggestion(autocompletionResponse);
                            setSuggestionStatus('success');
                        } else {
                            setSuggestion('');
                            setSuggestionStatus('empty');
                        }
                    }
                }
            } catch (error) {
                console.error('Error getting suggestions:', error);
                setSuggestionStatus('empty');
            }
        }, 500);
    } else {
        setSuggestionStatus('idle');
    }
}, [autoCompleteEnabled, ragEnabled]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    
    const newPosition = e.target.selectionStart;
    setCursorPosition(newPosition);
    
    textareaRef.current.selectionStart = newPosition;
    textareaRef.current.selectionEnd = newPosition;
    
    setSuggestion('');
    debouncedGetSuggestions(newText);
  };

  const handleKeyDown = (e) => {
    if (suggestion) {
        if (e.shiftKey && e.key === 'Tab') {
            e.preventDefault();
            const words = suggestion.split(' ');
            const firstWord = words[0];
            
            const beforeCursor = text.slice(0, cursorPosition);
            const afterCursor = text.slice(cursorPosition);
            
            const newText = beforeCursor + firstWord + ' ' + afterCursor;
            setText(newText);
            
            const newPosition = cursorPosition + firstWord.length + 1;
            setCursorPosition(newPosition);
            
            const remainingSuggestion = words.slice(1).join(' ');
            setSuggestion(remainingSuggestion);

            if (!remainingSuggestion) {
                setSuggestion('');
                debouncedGetSuggestions(newText);
            }
            
            setTimeout(() => {
                textareaRef.current.selectionStart = newPosition;
                textareaRef.current.selectionEnd = newPosition;
            }, 0);
        } else if (e.key === 'Tab') {
            e.preventDefault();
            const beforeCursor = text.slice(0, cursorPosition);
            const afterCursor = text.slice(cursorPosition);
            
            const newText = beforeCursor + suggestion + ' ' + afterCursor;
            setText(newText);
            
            const newPosition = cursorPosition + suggestion.length + 1;
            setCursorPosition(newPosition);
            
            setSuggestion('');
            debouncedGetSuggestions(newText);

            setTimeout(() => {
                textareaRef.current.selectionStart = newPosition;
                textareaRef.current.selectionEnd = newPosition;
            }, 0);
        }
    }
    // Handle other key events
    if (e.key === 'Backspace' && suggestion) {
        e.preventDefault();
        setSuggestion('');
    } else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(e.key)) {
        setSuggestion('');
    }
  };

  // Modify the handleClick function
  const handleClick = (e) => {
    const newPosition = e.target.selectionStart;
    
    // Clear suggestion if cursor moves
    if (newPosition !== cursorPosition) {
      setSuggestion('');
    }
    setCursorPosition(newPosition);
  };

  const handleSelect = (e) => {
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    // Clear suggestion if text is selected
    if (selectionStart !== selectionEnd) {
      setSuggestion('');
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    
    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      setSelectedText('');
    };
  }, []);

  // Chat interface text selection
  const handleTextSelection = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value.substring(start, end).trim();
      console.log(text);
      if (text && start !== end) {
        setSelectedText(text);
      } else {
        setSelectedText('');
      }
    }
  };

  const handleSelectReport = (reportContent) => {
    setText(reportContent);
  };


  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const renderTextWithSuggestion = () => {
    if (!suggestion) return text;
    
    const beforeCursor = text.slice(0, cursorPosition);
    const afterCursor = text.slice(cursorPosition);
    
    return (
        <>
            {beforeCursor}
            <span className="suggestion-text">{suggestion}</span>
            {afterCursor}
        </>
    );
  };

  const handleChatStart = async () => {
    // Reset chat messages to empty array
    setChatMessages([]);
    setChatLoading(false);
  };

  const handleChatMessage = async (newMessage) => {
    const userMessage = { role: 'user', content: newMessage };
    const updatedMessages = [...chatMessages, userMessage];
    setChatMessages(updatedMessages);
    setChatLoading(true);

    try {
      const response = await getChatResponse(updatedMessages);
      const assistantMessage = { role: 'assistant', content: response };
      setChatMessages([...updatedMessages, assistantMessage]);
    } catch (error) {
      console.error('Error getting chat response:', error);
    } finally {
      setChatLoading(false);
    }
  };

  const handleClearChat = () => {
    setChatMessages([]);
    setChatLoading(false);
  };

  useEffect(() => {
    if (SpeechRecognition && !recognition.current) {
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      
      recognition.current.onresult = (event) => {
        const currentTranscript = event.results[event.results.length - 1][0].transcript;

        if (event.results[event.results.length - 1].isFinal) {
          let needsSpace = false;
          
          setText(prevText => {
            const currentPosition = textareaRef.current.selectionStart;
            const beforeCursor = prevText.slice(0, currentPosition);
            const afterCursor = prevText.slice(currentPosition);
            
            // Check if we're at the start of a line
            const isNewLine = beforeCursor.endsWith('\n') || beforeCursor === '';
            // Only add space if we're not at the start of a line and don't already have a space
            needsSpace = !isNewLine && beforeCursor.length > 0 && !beforeCursor.endsWith(' ');
            const spacer = needsSpace ? ' ' : '';
            
            return beforeCursor + spacer + currentTranscript + afterCursor;
          });

          // Update cursor position to be after the new transcription
          setTimeout(() => {
            if (textareaRef.current) {
              const newPosition = textareaRef.current.selectionStart + currentTranscript.length + (needsSpace ? 1 : 0);
              textareaRef.current.selectionStart = newPosition;
              textareaRef.current.selectionEnd = newPosition;
              setCursorPosition(newPosition);
            }
          }, 0);
        }
      };
    }
  }, []); // Empty dependency array - only run once on mount

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'm') {
        e.preventDefault();
        
        if (!speechRecognitionSupported) {
          alert('Speech recognition is not supported in your browser. Please use Chrome for this feature.');
          return;
        }

        if (isDictating) {
          recognition.current?.stop();
          // Trigger autocompletion after stopping dictation
          const currentText = textareaRef.current?.value || '';
          debouncedGetSuggestions(currentText);
        } else {
          // Clear any existing suggestion first
          if (suggestion) {
            setSuggestion('');
          }
          
          // Focus the textarea before starting dictation
          textareaRef.current?.focus();
          // Ensure cursor position is maintained
          const currentPosition = textareaRef.current?.selectionStart || 0;
          setTimeout(() => {
            if (textareaRef.current) {
              textareaRef.current.selectionStart = currentPosition;
              textareaRef.current.selectionEnd = currentPosition;
              setCursorPosition(currentPosition);
            }
          }, 0);
          recognition.current?.start();
        }
        setIsDictating(!isDictating);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isDictating, speechRecognitionSupported, suggestion, debouncedGetSuggestions]); // Added dependencies

  const handleCopyToChat = () => {
    if (selectedText) {
      // Append selected text to existing chat input with a space in between
      setChatInput(prevInput => {
        const spacer = prevInput ? ' ' : '';
        return prevInput + spacer + selectedText;
      });
      setSelectedText('');
    }
  };

  return (
    <div className={`radcopilot-container ${sidebarOpen ? 'shifted' : ''}`}>
      <Sidebar 
        isOpen={sidebarOpen}
        toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        onLogout={onLogout}
        toggleHelp={toggleHelp}
        onSelectReport={handleSelectReport}
        text={text}
        setText={setText}
        setSuggestion={setSuggestion}
        setShowPopup={setShowPopup}
        selectedText={selectedText}
        autoCompleteEnabled={autoCompleteEnabled}
        setAutoCompleteEnabled={setAutoCompleteEnabled}
        ragEnabled={ragEnabled}
        setRagEnabled={setRagEnabled}
        chatMessages={chatMessages}
        chatLoading={chatLoading}
        onChatStart={handleChatStart}
        onChatMessage={handleChatMessage}
        onClearChat={handleClearChat}
        onCopyToChat={handleCopyToChat}
        chatInput={chatInput}
        setChatInput={setChatInput}
      />
      
      {showHelp && (
        <div className="help-modal">
          <h3>Keyboard Shortcuts</h3>
          <ul>
            <li><strong>Shift + Tab:</strong> Insert the first word of the suggestion</li>
            <li><strong>Tab:</strong> Insert the entire suggestion</li>
            <li><strong>Backspace:</strong> Clear the suggestion</li>
          </ul>
          <button onClick={toggleHelp}>Close</button>
        </div>
      )}
      
      <div className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
        <div className="radcopilot-main">
          <div className="textarea-wrapper">
            <div 
                className={`suggestion-indicator ${suggestionStatus}`}
                style={{display: suggestionStatus === 'idle' ? 'none' : 'block'}}
            />
            <div className="text-content">
                {renderTextWithSuggestion()}
            </div>
            <textarea
                ref={textareaRef}
                value={text}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                onSelect={handleSelect}
                onMouseUp={handleTextSelection}
                className="rad-textarea"
                placeholder="Write your report here..."
            />
          </div>
        </div>
        
        {showPopup && (
            <div className="copy-popup">
                Report saved successfully and copied to clipboard!
            </div>
        )}
      </div>
      <div className={`mic-indicator ${isDictating ? 'recording' : ''}`}>
        <MicIcon isRecording={isDictating} />
      </div>
    </div>
  );
};

export default RadCoPilot;
